.skeleton-miscellaneuos{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.skeleton-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center
}

.skeleton-inputs{
  display: flex;
  justify-content: center;
  align-content: center;
}
.skeletonInputDescription{
  margin-bottom: 8px;
}
.skeletonInputDescription > span{
  width: 90% !important;
}
.skeletonInputResume{
  margin: 16px 0;
}
.skeletonInputResume > span{
  width: 225px !important;
}
.skeletonInputOrders > span{
  width: 90% !important;
  height: 80px !important;
}
.skeletonInputOrders > span{
  width: 90% !important;
}
.skeletonInputTotal{
  margin: 16px 0
}
.skeletonInputTotal > span{
  width: 300px !important;
}
.skeletonButtonConfirm > span{
  border-radius: 4px;
  height: 36px !important;
  width: 170px !important;
}
.skeletonButtons{
  margin: 8px 0;
  display: flex;
  justify-content: center;
}
.skeletonButtons > span {
  height: 25px !important;
  width: 85px !important;
}

@media only screen and (min-width: 766px) {
  .skeleton-inputs > span{
    width: 660px !important;
  }
}