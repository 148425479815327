
.New-Modal-Product-Description{
  font-weight: 600;
  font-size: 1rem;
  margin-bottom: 0px;
}

.Max-Modal-Product-Description{
  font-weight: 500;
  font-family: 'Sweet Sans Pro';
  color: #0F1D3C;
  text-transform: capitalize!important;
  font-size: 1.2rem;
  margin-bottom: 0px;
}
.Max-Modal-Product-Description2{
  font-weight: 400;
  font-family: 'Sweet Sans Pro';
  color: #0F1D3C;
  text-transform: capitalize!important;
  font-size: 1rem;
  margin-bottom: 0px;
}
.New-modal-detail-Price{
  font-family: 'Sweet Sans Pro';
  color: #137CC1;
  font-size: 2rem;
}

.Max-modal-detail-Price{
  font-family: 'Sweet Sans Pro';
  color: #EBA039;
  font-size: 1.7rem;
  font-weight: 600;
  margin-bottom: 2rem!important;
}
.Max-modal-detail-Name{
  font-family: 'Sweet Sans Pro';
  color: #0F1D3C;
  font-size: 1rem;
  font-weight: 600;
}
.ant-modal-wrap::-webkit-scrollbar {
  display: none;
}
.New-Icon-Promotion-Text{
  color: #F12535;
  display: block;
  font-size: 1rem;
  margin-top: -0.4rem;
  margin-left: 0.5rem;
  font-family: 'Sweet Sans Pro';
}

.New-modal-Icon-Outlined-Gift{
  width: 20%;
  color: #F12535;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 2rem !important;
  left: 2rem !important;
}

.New-modal-detail-container-img{
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 2rem;
}


.New-modal-detail-container-img > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.New-modal-detail-item-img {
  height: 12em;
  display: flex;
  color: silver;
  cursor: pointer;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.New-modal-detail-item-img i {
  font-size: 8rem;
}

.New-modal-detail-details{
  display: flex;
  flex-direction: row;
  align-items: center;
}

.New-modal-detail-circle{
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-left: 0.5rem;
  margin-bottom: 0.7rem;
}

.New-modal-detail-promoPrice{
  color: #9c9d9d;
  margin-bottom: 0.5rem;
  text-decoration-line: line-through;
}

.New-modal-detail-rate{
  margin-bottom: 0.5rem;
}

.ant-rate-star-first, .ant-rate-star-second{
  color: #d8d8d8;
}

.New-modal-favorite > span > svg{
  width: 25px;
  height: 25px;
  color: #d8d8d8;
  cursor: pointer;
}

.New-modal-favorite-selected span > svg{
  width: 25px;
  height: 25px;
  color: #fadb14;
  cursor: pointer;
}

.ant-list-split .ant-list-header{
  border-bottom: 1px solid #d8d8d8;
}
.ant-modal-footer{
  padding-inline: 3rem;
  padding-bottom: 2rem;
}
.New-modal-detail-container-rateAndPrice{
  display: flex;
  justify-content: space-between;


}
.Max-modal-detail-container-rateAndPrice{
  display: flex;
  justify-content: space-between;
  margin-bottom: -1rem;

}

.detailContainerMax{
  margin-bottom: 1rem;
}
.New-product-Purchase{
  justify-content: center;
  width: 100%;
  height: 2rem;
  background-color:#137CC1;
}


.New-modal-detail-container-combos{
  display: flex;
  padding-top: 15px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.New-modal-detail-promoPrice{
  font-family: 'Sweet Sans Pro';
  color: #137CC1;
  font-size: 1.3rem;
  margin-top: 0.3rem;
  margin-bottom: -0.4rem;
  line-height: 1rem;
  color: rgba(107, 104, 112, 0.51);
  text-decoration: line-through;
}

.ant-modal-body{
  padding:2rem;
  padding-bottom: 0;
}

.ant-image-preview-img{
  background-color: #FFFFFF;
}

.New-modal-detail-container-combos-text-container{
  display: flex;
  flex-direction: column;
}
.New-modal-detail-container{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: min-content;
}
.New-container-modal-main{

}

.New-modal-detail-container-combos-title{
  background-color: white;
  font-size: 18px;
  font-weight: 600;
  margin: 0!important;
}

.New-modal-detail-container-combos-subtitle{
  background-color: white;
  font-size: 14px;
  font-weight: 500;
  color: #888888;
  margin: 0!important;
}

.New-modal-detail-container-combos-down{
  color: #BFBFBF;
  font-size: 26px;
}

.New-modal-detail-combos-item{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-block: 5px;
}

.New-modal-detail-combos-item-container{
  display: flex;
  flex-direction: column;
  margin-top: 15px;
}

.New-modal-detail-combos-item-title{
  font-size: 14px;
  font-weight: 500;
  color: #000000;
  margin: 0!important;
}

.New-modal-detail-combos-circle{
  height: 20px;
  width: 20px;
  border-radius: 50%;
  border: 2px solid #BFBFBF;

}